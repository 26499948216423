import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    useColorModeValue, useToast,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import uuid from 'react-uuid';
import apiClient from "../../services/auth/Api";


import ChatBubble from "../../components/ChatBubble";
import Parameters from "./Parameters";
import TokenCounter from "../../components/TokenCounter";


export default function ChatAi() {
    const toast = useToast()

    const [question, setQuestion] = useState('')
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [parameters, setParameters] = useState([
        'hjelpsom',
        'kreativ',
        'pedagogisk',
        'vennelig'
    ])
    const [settings, setSettings] = useState({
        temperature: 90,
        top_p: 100,
        max_tokens: 80
    })

    function handleClear() {
        setMessages([])
        toast({
            description: 'Meldingslogg tømt!',
            status: "success",
            duration: 5000,
            isClosable: true
        });
    }

    // Load messages from localstorage
    useEffect(() => {
        const mess = JSON.parse(localStorage.getItem('messages.log'))
        const params = JSON.parse(localStorage.getItem('chat.parameters'))
        if (mess) setMessages(mess)
        if (params) setParameters(params)

    }, [])

    // Set messages from localstorage when it is changed
    useEffect(() => {
        localStorage.setItem('messages.log', JSON.stringify(messages))
    }, [messages]);

    useEffect(() => {
        localStorage.setItem('chat.parameters', JSON.stringify(parameters))
    }, [parameters]);

    useEffect(() => {
        localStorage.setItem('chat.settings', JSON.stringify(settings))
    }, [settings]);


    function validateInput(i) {
        let description = ''

        if (!question) description = 'Hmm... Virker som at du ikke har stilt et spørsmål.';
        if (question.length < 6) description = 'Prøv å utdyp spørsmålet ditt litt mer!'
        if (question.length > 200) description = 'Oisann! Litt for langt spørsmål. Prøv å kort det ned litt!'

        if (description) {
            toast({
                title: "Ups...",
                description: description,
                status: "warning",
                duration: 5000,
                isClosable: true
            });
            return false
        }
        return true;
    }

    async function handleChat(e) {
        const x = JSON.parse(localStorage.getItem('messages.log'))

        e.preventDefault()

        if (!validateInput(question)) return;

        setMessages(messages => [{id: uuid(), text: question, sender: 'Deg', color: 'green.200'}, ...messages])

        setLoading(true)

        const api = apiClient()

        await api
            .post('/ai/idea', {
                question: question,
                personality: parameters,
                parameters: settings
            })
            .then(function (response) {
                if (response.data.status) {
                    setMessages(messages => [{
                        id: uuid(),
                        text: response.data.data,
                        sender: 'AI',
                        color: 'blue.200'
                    }, ...messages])
                }
            })
        setQuestion('')
        setLoading(false)
    }

    function handleParameters(params) {
        setParameters(params)
    }

    function handleSettings(settings) {
        setSettings(settings)
    }

    return (
        <Stack spacing={8} mx={'auto'} pt={12}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'} textAlign={'center'}>
                        Skriv med meg
                    </Heading>
                </Stack>

                <Stack mt={10} spacing={4}>
                    <form onSubmit={handleChat}>
                        <FormControl id="text">
                            <FormLabel>Still et spørsmål!</FormLabel>
                            <Input
                                disabled={loading}
                                value={question}
                                placeholder={"Spørsmål..."}
                                type="text"
                                onChange={(e) => setQuestion(e.target.value)}
                            />
                        </FormControl>
                    </form>
                    <Parameters handleParameters={handleParameters} handleSettings={handleSettings}/>
                    <Box justifyContent="space-between" display={"flex"} gap={5} pb={5}>
                        <Button
                            width={'70%'}
                            isLoading={loading}
                            colorScheme='teal'
                            onClick={handleChat}
                            loadingText="Genererer..."
                        >
                            Send 🤖
                        </Button>
                        <Button
                            display={'inline'}
                            width={'30%'}
                            onClick={handleClear}
                            loadingText="Genererer..."
                        >Tøm
                        </Button>
                    </Box>


                    <Box maxHeight={500} overflow={'scroll'}>
                        {messages.map((message) => (
                                <ChatBubble message={message}/>
                            )
                        )}
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
}