import {
    Alert, AlertDescription, AlertIcon, AlertTitle,
    Box,
    Button, Heading, Highlight,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader,
    ModalOverlay,
    Text, useDisclosure,
    useToast
} from "@chakra-ui/react";

import React from "react";

export default function Help({handleParameters}) {
    const toast = useToast()
    const {isOpen, onOpen, onClose} = useDisclosure()

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)


    return (
        <>
            <Text onClick={onOpen}>Hjelp</Text>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size={{base: 'full', md: 'md'}}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>AIR2101 guide</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Box>
                            <Heading mb={1} size={'md'}>Chat verktøy</Heading>
                            <Text>
                                Chat verktøyet er ment som å fungere som en hjelpende hånd når du vil trenge det. Den
                                kan svare på spørsmål, hjelpe deg med å drøfte problemstillinger, eller gi deg
                                fakta.
                            </Text>
                        </Box>
                        <Box mt={5}>
                            <Heading mb={1} size={'md'}>Egenskaper</Heading>
                            <Highlight query={["'snill'", "'hjelpsom'", "'kompetent innen AI'"]}
                                       styles={{px: '2', py: '1', rounded: 'sm', bg: 'teal.50'}}>
                                AI'ens egenskaper fungerer litt som mennesklige egenskaper. Med dette kan du gi AI'en
                                personlige egenskaper som: objektiv, morsom, god i matte osv. Egenskapene du gir AI'en
                                vil si noe om hvordan den skal tolke og spisse responsen den gir deg tilbake. F.eks om
                                du gir den egenskapene: 'snill', 'hjelpsom' og 'kompetent innen AI'. Vil AI'en kunne
                                respondere tilbake på bakgrunn av disse.
                            </Highlight>
                            <Text mt={2}>
                                Så her er det bare opp til deg hvordan du ønsker at AI'en skal svare deg.
                            </Text>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Lukk</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}