import apiClient from "./auth/Api";

export function getUser() {
    const api = apiClient()
    api
        .get('/user/me')
        .then(function (response) {
            if (response.data) {
                return response.data
            }
        })
        .catch(() => {
                return false
            }
        )
}

function setUser(user): void {
    localStorage.setItem('user', JSON.stringify(user))
}


function getTokensSpend() {
    const api = apiClient()
    api
        .get('/user/me/tokens')
        .then(function (response) {
            if (response.data.tokens_used) {

                return response.data;
            }
        })
        .catch(() => {
                return false
            }
        )
    return true
}