import {
    Badge, Button,
    Flex, Popover, PopoverArrow, PopoverBody,
    PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
    useToast
} from "@chakra-ui/react";
import apiClient from "../services/auth/Api";
import {useEffect, useState} from "react";

export default function TokenCounter() {
    const toast = useToast()

    const [tokensData, setTokensData] = useState({tokensUsed: 0, totalRequests: 0})

    useEffect(() => {
        getTokensSpend()
    }, []);


    function getTokensSpend() {
        const api = apiClient()
        api
            .get('/user/me/tokens')
            .then(function (response) {
                if (response.data.data) {
                    const data = response.data.data
                    setTokensData({tokensUsed: data.tokens_used, totalRequests: data.total_requests})
                }
            })
            .catch(() => {
                    return false
                }
            )
        return false
    }

    return (
        <Flex alignItems={"center"}>
            <Popover>
                <PopoverTrigger>
                    <Button onClick={getTokensSpend} colorScheme='teal'
                            variant='ghost'>{25000 - tokensData.tokensUsed}</Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow/>
                    <PopoverCloseButton/>
                    <PopoverHeader>Dine tokens</PopoverHeader>
                    <PopoverBody>
                        <Text mb={5}>
                            Du har brukt {tokensData.tokensUsed} tokens og har
                            igjen totalt {25000 - tokensData.tokensUsed} tokens i dag.
                        </Text>
                        <Text as={'i'}>
                            Tokens er måten vi setter en verdi på datakraft. Siden denne AI'en og AI generelt krever
                            ekstreme mengder datakraft for å drive, har vi begrenset mengden tokens du kan bruke per dag
                            til 25.000 tokens.
                        </Text>
                    </PopoverBody>
                </PopoverContent>
            </Popover>

        </Flex>
    )
}