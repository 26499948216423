import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Badge,
    Box,
    Button,
    CloseButton,
    Code,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, NumberDecrementStepper, NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";

import React, {useEffect, useState} from "react";
import {AddIcon, SearchIcon} from "@chakra-ui/icons";
import Parameter from "./Parameter";

export default function Parameters({handleParameters, handleSettings}) {
    const toast = useToast()
    const {isOpen, onOpen, onClose} = useDisclosure()

    const [settings, setSettings] = useState()
    const [parameter, setParameter] = useState()
    const [parameters, setParameters] = useState([
        'hjelpsom',
        'kreativ',
        'pedagogisk',
        'vennelig'
    ])

    const [temperature, setTemperature] = useState(90)
    const [topP, setTopP] = useState(100)
    const [maxTokens, setMaxTokens] = useState(80)

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)


    useEffect(() => {
        const params = JSON.parse(localStorage.getItem('chat.parameters'))
        const set = JSON.parse(localStorage.getItem('chat.settings'))
        console.log(set)

        if (params) setParameters(params)
        if (set) {
            setTemperature(set.temperature)
            setTopP(set.top_p)
            setMaxTokens(set.max_tokens)
        }

    }, [])

    function handleParameter(e) {
        e.preventDefault()
        if (!parameter) return
        if (parameters.length >= 4) {
            toast({
                title: 'For mange egenskaper!',
                description: "Du kan totalt gi AI'en 4 egenskaper",
                status: "error",
                duration: 2000,
                isClosable: true
            });
            return
        }

        const newParameters = [...parameters, parameter]
        setParameters(newParameters)
        setParameter('')

    }

    function deleteParam(thisParam) {
        const newParameters = parameters.filter(param => param !== thisParam)
        setParameters(newParameters)
    }

    function handleSave() {
        if (parameters.length === 0) {
            toast({
                title: 'Ingen egenskaper...',
                description: "Uten egenskaper, fungerer dessverre ikke AI'en så godt... Prøv å gi den noen egenskaper, feks: snill, hjelpsom etc.",
                status: "warning",
                duration: 5000,
                isClosable: true
            });
            return
        }
        const newSettings = ({
            temperature: temperature,
            top_p: topP,
            max_tokens: maxTokens
        })

        handleSettings(newSettings)
        handleParameters(parameters)
        onClose()
    }


    return (
        <>
            <Button onClick={onOpen}>Endre personlighet</Button>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size={{base: 'full', md: 'md'}}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Endre personlighet til AI</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        {/*<Box>*/}
                        {/*    <Alert status='info'>*/}
                        {/*        <AlertIcon/>*/}
                        {/*        <Box>*/}
                        {/*            <AlertTitle>Info!</AlertTitle>*/}
                        {/*            <AlertDescription>*/}
                        {/*                Ved å gi AI'en egenskaper, kan vi begrense, eller spesialere svarene vi får*/}
                        {/*                tilbake.*/}
                        {/*                For eksempel kan en egenskap være: "god i matte", "snill", "hjelpsom" eller*/}
                        {/*                "partisk".*/}
                        {/*            </AlertDescription>*/}
                        {/*        </Box>*/}
                        {/*    </Alert>*/}
                        {/*</Box>*/}
                        <form onSubmit={handleParameter}>
                            <FormControl mt={5}>
                                <FormLabel>AI'en er...</FormLabel>
                                <Flex gap={2}>
                                    <Input
                                        onChange={(e) => setParameter(e.target.value.toLowerCase())}
                                        value={parameter}
                                        placeholder='Eks: hjelpsom'/>
                                    <IconButton
                                        onClick={handleParameter}
                                        aria-label='Legg til egenskap'
                                        icon={<AddIcon/>}
                                    />
                                </Flex>
                            </FormControl>
                        </form>
                        <Flex mt={5}>
                            <Box width={"100%"}>
                                {parameters.map(param => (
                                        <Parameter param={param} deleteParam={deleteParam}/>
                                    )
                                )}
                            </Box>
                        </Flex>
                        <Box mt={4}>
                            <Text as={'i'}>Din AI er {parameters.map(param => (
                                `${param}, `
                            ))}</Text>
                        </Box>

                        <Box mt={8}>
                            <Heading size={'md'}>Avanserte instillinger</Heading>

                            <Flex alignItems={"center"} mt={6} gap={5}>
                                <Badge p={1} colorScheme={"green"}>Maks tokens per forespørsel</Badge>
                                <NumberInput width={'full'} size={'sm'} defaultValue={maxTokens} min={10} max={400}
                                             onChange={(x) => setMaxTokens(parseInt(x))}
                                >
                                    <NumberInputField/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper/>
                                        <NumberDecrementStepper/>
                                    </NumberInputStepper>
                                </NumberInput>
                            </Flex>

                            <Flex alignItems={"center"} mt={4} gap={5}>
                                <Badge p={1} colorScheme={"green"}>Kreativitet</Badge>
                                <Slider aria-label='slider-ex-4'
                                        onChange={(top_p) => setTopP(top_p)}
                                        defaultValue={topP}>
                                    <SliderTrack bg='blue.100'>
                                        <SliderFilledTrack bg='blue.300'/>
                                    </SliderTrack>
                                    <SliderThumb boxSize={6} bg={'blue.800'}>
                                        <Box color='tomato'/>
                                    </SliderThumb>
                                </Slider>
                                <Badge px={4}> {topP}%</Badge>
                            </Flex>

                            <Flex alignItems={"center"} mt={4} gap={5}>
                                <Badge p={1} colorScheme={"green"}>Sannsynlighet</Badge>
                                <Slider aria-label='slider-ex-4'
                                        onChange={(temperature) => setTemperature(temperature)}
                                        defaultValue={temperature}>
                                    <SliderTrack bg='blue.100'>
                                        <SliderFilledTrack bg='blue.300'/>
                                    </SliderTrack>
                                    <SliderThumb boxSize={6} bg={'blue.800'}>
                                        <Box color='red.200'/>
                                    </SliderThumb>
                                </Slider>
                                <Badge px={4}>{temperature}%</Badge>
                            </Flex>

                        </Box>

                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={handleSave} colorScheme='blue' mr={3}>
                            Lagre
                        </Button>
                        <Button onClick={onClose}>Avbryt</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}