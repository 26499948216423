import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    Flex,
    IconButton,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    useToast
} from "@chakra-ui/react";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {MinusIcon} from "@chakra-ui/icons";

export default function Parameter({param, deleteParam}) {
    const toast = useToast()

    function handleDelete() {
        deleteParam(param)
    }

    return (
        <ButtonGroup m={1} size='sm' isAttached variant='outline'>
            <Tag
                colorScheme={"cyan"}
                size={'lg'}
                key={param}
                borderRadius='full'
                variant='solid'
            >
                <TagLabel>{param}</TagLabel>
                <TagCloseButton
                    onClick={handleDelete}
                    aria-label='Fjern parameter'
                    icon={<MinusIcon/>}/>
            </Tag>
        </ButtonGroup>
    )
}