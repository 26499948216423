import {Box, Button, Text, useToast} from "@chakra-ui/react";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {CopyIcon} from "@chakra-ui/icons";
import {motion} from "framer-motion"
import {useEffect} from "react";

export default function ChatBubble({message}) {
    const toast = useToast()

    return (
        <Box key={message.id} rounded={'lg'} mb={2} p={2} border={'2px'}
             borderColor={message.color}>
            <Text fontWeight={700} align={'left'}>{message.sender}:</Text>
            <Text align={'left'}>{message.text}</Text>
            <Box width={'100%'}>
                <CopyToClipboard text={message.text}>
                    <motion.button
                        whileHover={{
                            scale: 1.2,
                            transition: {duration: 0.2},
                        }}
                        whileTap={{scale: 0.9}}
                    >
                        <Button mt={3} size={'sm'} onClick={() =>
                            toast({
                                title: 'Kopiert tekst',
                                description: `"${message.text}"`,
                                status: 'success',
                                duration: 2000,
                                isClosable: true,
                            })
                        }>
                            <CopyIcon/>
                        </Button>
                    </motion.button>
                </CopyToClipboard>
            </Box>
        </Box>
    )
}