import {Routes, Route} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from './pages/Dashboard'
import {RequireToken} from "./services/auth/Auth";
import Register from "./pages/Register";
import React, {useEffect, useState} from "react";


import {Container} from '@chakra-ui/react'
import Nav from "./components/Navbar";
import {getUser} from "./services/MainService";
import {useNavigate} from "react-router";

const AuthApi = React.createContext();


function App() {


    return (
        <div className="App">
            <Nav/>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>

                {/*Protected*/}
                <Route path="/dashboard/*" element={
                    <RequireToken>
                        <Dashboard/>
                    </RequireToken>
                }
                />
            </Routes>
        </div>
    );
}

export default App;
