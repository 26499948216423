import {useNavigate} from "react-router";
import {fetchToken, setToken} from "../services/auth/Auth";
import {useState} from "react";
import {Box, Button, Container, FormControl, FormLabel, Heading, Input, Text} from "@chakra-ui/react";
import {endpoint} from "../services/auth/Api";
import axios from "axios";
import {getUser} from "../services/MainService";

export default function Register() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const register = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (!name || !password || !email) {
            return;
        } else {
            await axios
                .post(`${endpoint()}/user/register`, {
                    name: name,
                    email: email,
                    password: password,
                    code: code
                })
                .then(function (response) {
                    if (response.data.access_token) {
                        setToken(response.data.access_token);
                        getUser()
                        navigate("/dashboard");
                    }
                })
                .catch(function (error) {
                    console.log(error, "error");
                });
        }
        setLoading(false)
    };

    function login(e) {
        e.preventDefault()
        navigate("/")
    }

    function navigateToDash() {
        navigate('/dashboard')
    }

    return (
        <Container width={'full'} px={{base: 2, md: 6}}>
            <div style={{minHeight: 800, marginTop: 30}}>
                <Heading>Registrer</Heading>
                <Text>{message}</Text>
                <div style={{marginTop: 30}}>
                    {fetchToken() ? (
                        <>
                            <p>you are logged in</p>
                            <Button onClick={navigateToDash}>Dashbord</Button>
                        </>
                    ) : (
                        <div>
                            <form>
                                <FormControl>

                                    <FormLabel>Navn</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder={'Navn navnesen'}
                                        onChange={(e) => setName(e.target.value)}
                                    />

                                    <FormLabel mt={5}>Epost</FormLabel>
                                    <Input
                                        type="email"
                                        placeholder={'@student.kristiania.no'}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                    <FormLabel mt={5}>Kode</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder={"Tillgangskode"}
                                        onChange={(e) => setCode(e.target.value)}
                                    />

                                    <FormLabel mt={5}>Passord</FormLabel>
                                    <Input
                                        type="password"
                                        placeholder={"passord"}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />

                                    <Box justifyContent="space-between" display={"flex"} gap={5} mt={5}>
                                        <Button
                                            isLoading={loading}
                                            loadingText={"Registrerer..."}
                                            onClick={register}
                                            width={'100%'}
                                            colorScheme='blue'
                                        >Registrer</Button>

                                        {loading ? '' :
                                            <Button onClick={login} width={'40%'} colorScheme='green'>Har du
                                                konto?</Button>
                                        }
                                    </Box>
                                </FormControl>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
}
