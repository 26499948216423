import {useLocation, Navigate} from "react-router-dom"
import {getUser} from "../MainService";

export const setToken = (token) => {
    localStorage.setItem('token', token)// make up your own token
}

export const fetchToken = () => {
    return localStorage.getItem('token')
}

export function RequireToken({children}) {
    let auth = fetchToken()
    let location = useLocation()

    if (!auth) {
        return <Navigate to='/' state={{from: location}}/>;
    }

    return children;
}