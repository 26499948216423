import axios from "axios";
import {fetchToken} from "./Auth";

/**
 * @description
 * Method for creating an axios instance
 * from base url, with default headers and credentials
 */


export function endpoint() {
    return "https://air-2101-api.herokuapp.com"
}

function apiClient() {
    return axios.create({
        baseURL: endpoint(),
        withCredentials: true,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + fetchToken(),
        },
    })
}

export default apiClient;