import {useNavigate} from "react-router";
import {fetchToken, setToken} from "../services/auth/Auth";
import {useState} from "react";
import apiClient from "../services/auth/Api";

import {
    FormControl,
    FormLabel,
    Input, Button, Box, Heading, Container
} from '@chakra-ui/react'
import axios from "axios";
import {endpoint} from "../services/auth/Api";
import {getUser} from "../services/MainService";


export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false)

    const login = async (e) => {
        e.preventDefault()
        setLoading(true)

        if ((email === "") & (password === "")) {
            return;
        } else {
            await axios
                .post(`${endpoint()}/user/login`, {
                    email: email,
                    password: password,
                })
                .then(function (response) {
                    console.log(response)
                    if (response.data.access_token) {
                        setToken(response.data.access_token);
                        navigate("/dashboard");
                        getUser()
                    }
                })
                .catch(function (error) {
                    console.log(error, "error");
                });
        }
        setLoading(false)
    };

    function register(e) {
        e.preventDefault()
        navigate("/register")
    }

    function navigateToDashboard() {
        navigate('/dashboard')
    }

    return (
        <Container width={'full'} px={{base: 2, md: 6}}>
            <div style={{minHeight: 800, marginTop: 30}}>
                <Heading>Login</Heading>
                <div style={{marginTop: 30}}>
                    {fetchToken() ? (
                        <>
                            <p>you are logged in</p>
                            <Button onClick={navigateToDashboard}>Dashbord</Button>
                        </>
                    ) : (
                        <div>
                            <FormControl>
                                <FormLabel>Epost</FormLabel>
                                <Input
                                    type="email"
                                    placeholder={'@student.kristiania.no'}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <FormLabel mt={5}>Passord</FormLabel>
                                <Input
                                    type="password"
                                    placeholder={"passord"}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <Box justifyContent="space-between" display={"flex"} gap={5} mt={5}>
                                    <Button onClick={login}
                                            isLoading={loading}
                                            width={loading ? '100%' : '70%'}
                                            colorScheme='green'
                                            loadingText="Logger inn..."
                                    >Login</Button>
                                    {loading ? '' :
                                        <Button onClick={register} width={'30%'}
                                                colorScheme='blue'>{loading ? '' : 'Ny bruker?'}</Button>
                                    }
                                </Box>
                            </FormControl>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
}
