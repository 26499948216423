import {useNavigate} from "react-router";
import {Box, Container, Flex, Heading, SimpleGrid, Text} from "@chakra-ui/react";
import ChatAi from "../modules/chat/ChatAi";
import {Reorder} from "framer-motion"
import {useState} from "react";
import IdeaAi from "../modules/idea/IdeaAi";
import SummaryAi from "../modules/summary/SummaryAi";


export default function Dashboard() {
    const navigate = useNavigate();

    return (
        <div style={{marginTop: 20, minHeight: 700}}>
            <Heading align={"center"}>Dashboard</Heading>
            <Text align={"center"}>Hei! Velkommen til AI verktøy for AIR2101</Text>
            <SimpleGrid minChildWidth='350px' spacing='40px' gap={5}>
                <Container width={'full'} px={{base: 2, md: 6}}>
                    <ChatAi/>
                </Container>
                {/*<Container px={{base: 0, md: 6}} width={'full'}>*/}
                {/*    <SummaryAi/>*/}
                {/*</Container>*/}
            </SimpleGrid>
        </div>
    );
}